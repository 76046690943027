import SuperLayout from "./superlayout.js";
import PageCache from "./pagecache.js";

// $('img.badge').hover(function(){
//   window.mytimeout = setTimeout(function(){
//       $("h3.better").animate({"left": "125px"}, 1200);
//   }, 2000);
// }, function(){
//   clearTimeout(window.mytimeout);    
// });

/** @type {SuperLayout} superlayout */

document.addEventListener('DOMContentLoaded',function(){
    console.log('SL: boot');
   if(typeof superlayout  == 'undefined' ){ console.log('SL: module not enabled');  return;}
    
   window.superlayout = new SuperLayout(superlayout);
 //   initMenuHover();

   
   if(typeof prestashop !='undefined' && superlayout.config.async && superlayout.config.async.pageload==true)
   prestashop.on('showModal',(data)=>{
         // console.log(data)
         let node = document.getElementById('blockcart-modal');
     
         if(node){
       var cb = function(){  let m = document.getElementById('blockcart-modal'); m.style.display='none'; $(m).remove(); 
       document.querySelector('.modal-backdrop').remove();
     }
           superlayout.initLinks(node,cb);
  
     }
         return true;
     });
 
   
   new PerformanceObserver((entryList) => {
     const [pageNav] = entryList.getEntriesByType('navigation');
   
     console.log(`SL TTFB: ${pageNav.responseStart}`);
   }).observe({
     type: 'navigation',
     buffered: true
   });

   superlayout.initReadmore();
   superlayout.initCurrency();

   function btn_disable_event(wrapper=null){
       if(!wrapper) wrapper=document;
       if(typeof wrapper == 'string') wrapper=document.querySelector(wrapper);
       if(!wrapper) return;
       wrapper.querySelectorAll('.btn').forEach(a=>{
        console.log('SL: init events');
        a.addEventListener('click',()=>{
            a.classList.add('clicked');
            if(a.getAttribute('data-events')) return null;
            console.log('class list clicked');
            a.setAttribute('data-events','event-disabled');
            setTimeout(()=>{
                a.classList.remove('clicked');
            },2000);
        });
       });
    }
    btn_disable_event();
    prestashop.on('updateCart', ()=>{btn_disable_event('.modal');});
});
 

window.addEventListener('DOMContentLoaded',function(){
    if(!window.superlayout){ console.log("PC: superlayout not present "); return ;}
    console.log('SL PC: boot');
    if(superlayout.config.fullpage_cache!=true){ 
      console.log('SL PC: not active');
      return;
    }
    window.pagecache=new PageCache();
    window.pagecache.init();
    // document.body.setAttribute('cache','fullpagecache');
    window.addEventListener('load',function(){
        document.body.classList.add('cache-loaded');
        console.log('SL PC: pagecache ended, cache-loaded class');
    });
});

 