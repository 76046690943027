export default class PageCache { 
    iso_code='en';
    cache={'cookie':{'logged':false,'token':'token'}};
    constructor(){
        // if(window.superlayout.cache){ 
        // window.onload=()=>this.init;
        //}
        console.log("SL PC: boot");
        try{
        this.cache.cookie=JSON.parse(window.localStorage.getItem('cookie'));
        }catch(e){};
        
        if(typeof prestashop.language!=undefined)
            this.iso_code= prestashop.language.iso_code;
    }

    getCookieCurrency(){
        let sign =document.cookie.match(new RegExp('(^| )currency=([^;]+)')); 
        if(!sign) return false;
        return sign[2];
    }
    
    html_loggedin(logged=true){
        if(logged){
            console.log('SL PC: user seems to logged in ');
            document.querySelectorAll('.user-info .logged-in').forEach(a=>a.style.display="inline");
            document.querySelectorAll('.user-info .logged-out').forEach(a=>a.style.display="none");
        }
    }
    get_cookie(){
        fetch('/module/superlayout?action=cookie').then(response=>response.json().then(json=>{
            if(json.logged){
                this.html_loggedin(true);
            }
            this.cache.cookie=json;
            window.localStorage.setItem('cookie',JSON.stringify(json));
        }));
    }
    init(){
  
        if(this.cache.cookie && this.cache.cookie.logged){
            this.html_loggedin(this.cache.cookie.logged)
        }
        this.get_cookie();
        this.display_cart(); 
        let sign=this.getCookieCurrency();
        // if(!sign){ sign='GBP'; document.cookie='currency=GBP;path=/'; console.log('setting default cookie');}
        console.log("SL PC: page cache init currency " + sign); 
        if(sign!='GBP' && sign) this.change_currency(sign,false);
    
          //set current iso 
          document.querySelectorAll('.currency-selector2.js-dropdown li a').forEach((a)=>{ a.addEventListener('click',(e)=>{
            // e.preventDefault();
            let iso = a.getAttribute('data-iso');
            // alert('changing from ' + sign + ' to currency to '+iso);
            // this.change_currency(iso);
            // return true;
          })});

          document.querySelectorAll('.user-info a.logged-in[href*="mylogout="]').forEach((a)=>{
            console.log('SL PC: registered logout a:' + a.classList);
            a.addEventListener('click',()=>{
                // event.preventDefault();
                this.cache.cookie.logged=false;
                window.localStorage.setItem('cookie',JSON.stringify(this.cache.cookie));
                return true;
            });
          })

          console.log("SL PC: currency selector dynamic");
        // select_currency();
    }
    
    
    display_cart() {
        //refresh url 
        console.log('SL PC: redisplay cart');
        let current = this.getCurrency();

        let blockcart  = document.querySelector('.blockcart');
        if(!blockcart) return;
        var refreshURL = blockcart.getAttribute('data-refresh-url');
        refreshURL+='?bypass';
        if(current)
            refreshURL+='&SubmitCurrency=1&id_currency='+current.id_currency;
        fetch(refreshURL).then(response => response.json()).then(json => {
            //console.log(json.preview);
            let preview = json.preview;

            var div = document.createElement('div');
            div.innerHTML = preview.trim();
            var cart = div.querySelector('.blockcart');
            // console.log(cart);

            document.querySelector('.blockcart').innerHTML = cart.innerHTML;
        });

   
        document.querySelectorAll('body[cache=fullpagecache] input[name=token]').forEach((i)=>{
            i.value=this.cache.cookie.token;
            console.log('SL PC: set token from cookie');
        });
    }
    getCurrency(){
        return this.getCurrencyFromSign(this.getCookieCurrency());
    }
    getCurrencyFromSign(dest){
        let current =null;
        if(typeof window.superlayout.config.currencies =='undefined') return null;
        if(parseFloat(dest)){
            current=   Object.values(window.superlayout.config.currencies).find(o=>o.id_currency==dest);
        }else
            current = window.superlayout.config.currencies[dest];
        return current; 
    }

    change_currency(dest,withCart=true) {
        if(!dest) return;
        // document.cookie='currency='+dest+';path=/';
        // console.log(document.cookie);
        let sel = document.querySelector('.currency-selector.js-dropdown');
        let current = this.getCurrencyFromSign(dest);
        if(!current){
            console.error('Cannot change currency. ');
            return;
        }
        console.log('SL PC: changing currency to ' + current.iso_code +  ' from sign' +dest);
        // console.log(document.cookie);
        if(withCart) this.display_cart();

        if (sel) {
            console.log('SL PC: setting current iso in selector');
            let e = sel.querySelector('button span')
            if (e)e.innerHTML = current.iso_code + ' ' + current.symbol;
            e.style.visibility='visible';

        }
        var getClosest = function (elem, func) {
            for (; elem && elem !== document; elem = elem.parentNode) {

                if (func(elem)) return elem;
            }
        };

        //remember about precision=0 in math_round 
        var priceConversion=(price,mode=null)=>{
            price *=current.conversion_rate;
            if(isNaN(price)) return;
             if(!mode) mode= parseInt(window.superlayout.config.round_mode);

            if(mode==2){
                if((Math.ceil(price)-price)>=0.5){

                }
                price=price.toFixed();

            }else 
                price=price.toFixed(mode);

            return price;

        }
        
        var convertPrice= (price)=>{
            price=priceConversion(price);
            let d = [price,current.symbol];
        
            if(['en','ja','nl','zh','ar','tr','pt'].includes(this.iso_code))
                d.reverse();

            try{
                return new Intl.NumberFormat(this.iso_code, { style: "currency", currency: current.iso_code }).format(
                    price,
                  );
                }catch(e){

             }
            return  d.join(' ');
        }
        console.log('SL PC: converting prices with mode ' + window.superlayout.config.round_mode);
        document.querySelectorAll('.product-flag.discount:not(.percentage)').forEach(function (e) {
            // debugger;
            var par = getClosest(e, function (p) {
                if (p.getAttribute('data-id-product')) { return true; }
                if (p.classList.contains('js-product-container')) { return true; }
            });

            if (!par) return;
            // let id_product = par.getAttribute('data-id-product');;
            // let id_attribute = par.getAttribute('data-id-product-attribute');
            e.style.visibility='visible';
            let r= par.querySelector("[name=price_old][data-type=amount]");
            if(!r) return;
            //find hidden element and apply 
            let price = parseFloat(r.value);
     
            e.innerHTML = '- '+convertPrice(price);
            // alert(e.innerHTML);
            // console.log('SL PC: changing discount flags '+id_product);
        })

        document.querySelectorAll('.js-price-save').forEach(function (e) {
            var par = getClosest(e, function (p) {
                if (p.getAttribute('data-id-product')) { return true; }
                if (p.classList.contains('js-product-container')) { return true; }
            });
            if (!par) return;
            e.style.visibility='visible';
            let r= par.querySelector("[name=price_old]");
            if(!r || !r.value) { console.log('price cold not found'); return;}
            let price = parseFloat(r.value);
     
            e.innerHTML = convertPrice(price);
        });


        document.querySelectorAll('.js-price').forEach((e) =>{

            let prev=e.previousElementSibling;
            if(prev.name=='price_gbp'){
                let price = prev.value;
                let price_old=false;
                let discount=0;
                let info='';
              
                if(prev.hasAttribute('data-price-old') && prev.hasAttribute('data-discount')){
                    price_old=prev.getAttribute('data-price-old');
                    discount=prev.getAttribute('data-discount');
                }
                
                let html_price=convertPrice(price);
                if(price_old && discount){ //not used for now
                    // info='product with discount calculation';
                    // html_price=priceConversion(price_old,10) - priceConversion(discount,10);
                }
                e.innerHTML=  html_price; 

                e.style.visibility='visible';

                console.log('SL PC: changing product js-price '+current.iso_code+' ' +current.conversion_rate + ' '+info);
            }
        });
    }

}

